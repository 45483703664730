/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportCourseOfferingEntity from 'Models/Security/Acl/CurriculumSupportCourseOfferingEntity';
import UserCourseOfferingEntity from 'Models/Security/Acl/UserCourseOfferingEntity';
import InstitutionAdminCourseOfferingEntity from 'Models/Security/Acl/InstitutionAdminCourseOfferingEntity';
import AdminCourseOfferingEntity from 'Models/Security/Acl/AdminCourseOfferingEntity';
import VisitorsCourseOfferingEntity from 'Models/Security/Acl/VisitorsCourseOfferingEntity';
import StudentAdvisorCourseOfferingEntity from 'Models/Security/Acl/StudentAdvisorCourseOfferingEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICourseOfferingEntityAttributes extends IModelAttributes {
	prerequisite: number;
	studyPeriod: number;
	yearLevel: number;
	enabled: boolean;
	requirement: number;
	note: string;

	campusId?: string;
	campus?: Models.CampusEntity | Models.ICampusEntityAttributes;
	courseId: string;
	course: Models.CourseEntity | Models.ICourseEntityAttributes;
	programRequirementId: string;
	programRequirement: Models.RequirementEntity | Models.IRequirementEntityAttributes;
	termId?: string;
	term?: Models.TermEntity | Models.ITermEntityAttributes;
	generatedTerms: Array<
		| Models.GeneratedTermGeneratedCourseOffering
		| Models.IGeneratedTermGeneratedCourseOfferingAttributes
	>;
	planSelections: Array<
		| Models.PlanSelectionEntity
		| Models.IPlanSelectionEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CourseOfferingEntity', 'Course Offering')
// % protected region % [Customise your entity metadata here] end
export default class CourseOfferingEntity extends Model
	implements ICourseOfferingEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportCourseOfferingEntity(),
		new UserCourseOfferingEntity(),
		new InstitutionAdminCourseOfferingEntity(),
		new AdminCourseOfferingEntity(),
		new VisitorsCourseOfferingEntity(),
		new StudentAdvisorCourseOfferingEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Prerequisite'] off begin
	/**
	 * Single digit to singify whether the course is required by subsequent courses
	 */
	@Validators.Required()
	@Validators.Min(0)
	@Validators.Max(9)
	@Validators.Integer()
	@observable
	@attribute<CourseOfferingEntity, number>()
	@CRUD({
		name: 'Prerequisite',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public prerequisite: number;
	// % protected region % [Modify props to the crud options here for attribute 'Prerequisite'] end

	// % protected region % [Modify props to the crud options here for attribute 'Study Period'] off begin
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<CourseOfferingEntity, number>()
	@CRUD({
		name: 'Study Period',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public studyPeriod: number;
	// % protected region % [Modify props to the crud options here for attribute 'Study Period'] end

	// % protected region % [Modify props to the crud options here for attribute 'Year Level'] off begin
	/**
	 * single digit year level of the course
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<CourseOfferingEntity, number>()
	@CRUD({
		name: 'Year Level',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public yearLevel: number;
	// % protected region % [Modify props to the crud options here for attribute 'Year Level'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enabled'] off begin
	/**
	 * Whether an offering is available to students
	 */
	@Validators.Required()
	@observable
	@attribute<CourseOfferingEntity, boolean>()
	@CRUD({
		name: 'Enabled',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enabled: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requirement'] off begin
	@Validators.Required()
	@Validators.Min(0)
	@Validators.Max(9)
	@Validators.Integer()
	@observable
	@attribute<CourseOfferingEntity, number>()
	@CRUD({
		name: 'Requirement',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public requirement: number;
	// % protected region % [Modify props to the crud options here for attribute 'Requirement'] end

	// % protected region % [Modify props to the crud options here for attribute 'Note'] off begin
	@observable
	@attribute<CourseOfferingEntity, string>()
	@CRUD({
		name: 'Note',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public note: string;
	// % protected region % [Modify props to the crud options here for attribute 'Note'] end

	/**
	 * Instances of a Course offered within this campus
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Campus'] off begin
		name: 'Campus',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.CampusEntity,
		// % protected region % [Modify props to the crud options here for reference 'Campus'] end
	})
	public campusId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public campus: Models.CampusEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course'] off begin
		name: 'Course',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.CourseEntity,
		// % protected region % [Modify props to the crud options here for reference 'Course'] end
	})
	public courseId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public course: Models.CourseEntity;

	/**
	 * Offering available within this Version Requirement
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Requirement'] off begin
		name: 'Program Requirement',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.RequirementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Requirement'] end
	})
	public programRequirementId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programRequirement: Models.RequirementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Term',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.TermEntity,
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public termId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public term: Models.TermEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Term'] off begin
		name: 'Generated Term',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GeneratedTermGeneratedCourseOffering,
		optionEqualFunc: makeJoinEqualsFunc('generatedTermId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseOfferingEntity',
			oppositeEntityName: 'generatedTermEntity',
			relationName: 'generatedCourseOffering',
			relationOppositeName: 'generatedTerm',
			entity: () => Models.CourseOfferingEntity,
			joinEntity: () => Models.GeneratedTermGeneratedCourseOffering,
			oppositeEntity: () => Models.GeneratedTermEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Generated Term'] end
	})
	public generatedTerms: Models.GeneratedTermGeneratedCourseOffering[] = [];

	/**
	 * Selected Course Offerings to be scheduled
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Plan Selection'] off begin
		name: 'Plan Selections',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.PlanSelectionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'planSelections',
			oppositeEntity: () => Models.PlanSelectionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Plan Selection'] end
	})
	public planSelections: Models.PlanSelectionEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICourseOfferingEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICourseOfferingEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.prerequisite !== undefined) {
				this.prerequisite = attributes.prerequisite;
			}
			if (attributes.studyPeriod !== undefined) {
				this.studyPeriod = attributes.studyPeriod;
			}
			if (attributes.yearLevel !== undefined) {
				this.yearLevel = attributes.yearLevel;
			}
			if (attributes.enabled !== undefined) {
				this.enabled = attributes.enabled;
			}
			if (attributes.requirement !== undefined) {
				this.requirement = attributes.requirement;
			}
			if (attributes.note !== undefined) {
				this.note = attributes.note;
			}
			if (attributes.campusId !== undefined) {
				this.campusId = attributes.campusId;
			}
			if (attributes.campus !== undefined) {
				if (attributes.campus === null) {
					this.campus = attributes.campus;
				} else if (attributes.campus instanceof Models.CampusEntity) {
					this.campus = attributes.campus;
					this.campusId = attributes.campus.id;
				} else {
					this.campus = new Models.CampusEntity(attributes.campus);
					this.campusId = this.campus.id;
				}
			}
			if (attributes.courseId !== undefined) {
				this.courseId = attributes.courseId;
			}
			if (attributes.course !== undefined) {
				if (attributes.course === null) {
					this.course = attributes.course;
				} else if (attributes.course instanceof Models.CourseEntity) {
					this.course = attributes.course;
					this.courseId = attributes.course.id;
				} else {
					this.course = new Models.CourseEntity(attributes.course);
					this.courseId = this.course.id;
				}
			}
			if (attributes.programRequirementId !== undefined) {
				this.programRequirementId = attributes.programRequirementId;
			}
			if (attributes.programRequirement !== undefined) {
				if (attributes.programRequirement === null) {
					this.programRequirement = attributes.programRequirement;
				} else if (attributes.programRequirement instanceof Models.RequirementEntity) {
					this.programRequirement = attributes.programRequirement;
					this.programRequirementId = attributes.programRequirement.id;
				} else {
					this.programRequirement = new Models.RequirementEntity(attributes.programRequirement);
					this.programRequirementId = this.programRequirement.id;
				}
			}
			if (attributes.termId !== undefined) {
				this.termId = attributes.termId;
			}
			if (attributes.term !== undefined) {
				if (attributes.term === null) {
					this.term = attributes.term;
				} else if (attributes.term instanceof Models.TermEntity) {
					this.term = attributes.term;
					this.termId = attributes.term.id;
				} else {
					this.term = new Models.TermEntity(attributes.term);
					this.termId = this.term.id;
				}
			}
			if (attributes.generatedTerms !== undefined && Array.isArray(attributes.generatedTerms)) {
				for (const model of attributes.generatedTerms) {
					if (model instanceof Models.GeneratedTermGeneratedCourseOffering) {
						this.generatedTerms.push(model);
					} else {
						this.generatedTerms.push(new Models.GeneratedTermGeneratedCourseOffering(model));
					}
				}
			}
			if (attributes.planSelections !== undefined && Array.isArray(attributes.planSelections)) {
				for (const model of attributes.planSelections) {
					if (model instanceof Models.PlanSelectionEntity) {
						this.planSelections.push(model);
					} else {
						this.planSelections.push(new Models.PlanSelectionEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		generatedTerms {
			${Models.GeneratedTermGeneratedCourseOffering.getAttributes().join('\n')}
			generatedTerm {
				${Models.GeneratedTermEntity.getAttributes().join('\n')}
			}
		}
		campus {
			${Models.CampusEntity.getAttributes().join('\n')}
		}
		course {
			${Models.CourseEntity.getAttributes().join('\n')}
		}
		programRequirement {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
		term {
			${Models.TermEntity.getAttributes().join('\n')}
		}
		planSelections {
			${Models.PlanSelectionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			generatedTerms: {},
			planSelections: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'generatedTerms',
							'planSelections',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CourseOfferingEntity.prototype, 'created');
CRUD(modifiedAttr)(CourseOfferingEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
